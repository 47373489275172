body {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}
.register_section {
  display: block;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: transparent;
}
.register_text-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 300px;
  padding-right: 40px;
  padding-left: 40px;
  float: left;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: -webkit-linear-gradient(270deg, rgba(231, 56, 46, .8), rgba(231, 56, 46, .8)), url("images/City-Graphic.jpg");
  background-image: linear-gradient(180deg, rgba(231, 56, 46, .8), rgba(231, 56, 46, .8)), url("images/City-Graphic.jpg");
  background-position: 0px 0px, 50% 100%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, scroll;
}
  .register_section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
    .register_text-wrap {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .register_text-wrap {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    float: none;
  }
    .register_section {
    display: block;
  }
    .register_text-wrap {
    height: 350px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .register-step{
  box-sizing: border-box;
  background-color: white;
  flex-flow: column;
  justify-content: center;
  min-height: 0;
  opacity: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
}

.register-step.visible{
  padding: 20px;
  display: flex;
  height: auto;
  min-height: min-content;
  opacity: 1;
}


@supports not (min-height: min-content) {
  .register-step.visible {
    min-height: 1325px;
  }
  @media (min-width: 767px) {
    .register-step.visible{
      min-height: 750px;
    }
  }
}

.register-modal{
  position: fixed;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,.6);
  opacity: 0;
  transition: opacity 1s ease-in;
  display: flex;
  flex: 1;
  overflow: auto;
  z-index: -10;
  flex-flow: column;
  min-height: calc(100vh - 70px);
}
.register-modal.visible{
  z-index: 100;
  opacity: 1;
}
h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Bebasneue;
  color: #000;
  font-size: 70px;
  line-height: 80px;
  font-weight: 400;
}

h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Montserrat, sans-serif;
  font-size: 32px;
  line-height: 36px;
  font-weight: 500;
  text-align: center;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Bebasneue;
  color: #e7382e;
  font-size: 28px;
  line-height: 30px;
  font-weight: 400;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Montserrat, sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: Montserrat, sans-serif;
  color: #e7382e;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  text-align: left;
  text-transform: uppercase;
}

h6 {
  margin-bottom: 10px;
  font-family: Bebasneue;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
}

p {
  margin-bottom: 10px;
  font-family: Montserrat, sans-serif;
  color: #000;
  font-size: 16px;
  line-height: 25px;
  font-weight: 300;
  text-align: left;
}

a {
  text-decoration: underline;
}

li {
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Montserrat, sans-serif;
  color: #000;
  font-size: 16px;
  font-weight: 300;
}

strong {
  font-weight: bold;
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  top: 0px;
  right: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .09);
}

.c-menu_nav-link {
  display: block;
  margin-right: 4px;
  margin-left: 4px;
  padding: 15px 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: Montserrat, sans-serif;
  color: #e7382e;
  font-size: 16px;
  text-transform: uppercase;
}

.c-menu_nav-link.w--current {
  color: #e7382e;
}

.c-menu_nav-link.menu:hover {
  color: #807e7e;
  font-size: 16px;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5%;
  float: right;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 90vh;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('images/Home-background-hero.jpg');
  background-position: 100% 100%;
  background-size: cover;
}

.hero-section.integrations {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url('images/Intergrations-bg.jpg');
  background-repeat: no-repeat;
}

.hero-section.product {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  height: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('images/Product-bg2x.jpg');
  background-position: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.h1_large-heading {
  font-family: Bebasneue;
  color: #e7382e;
  font-size: 80px;
  line-height: 80px;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
}

.h1_large-heading.black {
  color: #000;
}

.paragraph {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Montserrat, sans-serif;
  color: #3c4e60;
  font-size: 16px;
  line-height: 25px;
  font-weight: 300;
  text-align: left;
}

.link_red-text {
  margin-top: 5px;
  margin-bottom: 5px;
  float: none;
  font-family: Montserrat, sans-serif;
  color: #e7382e;
  font-size: 18px;
}

.link_red-text:hover {
  color: #807e7e;
}

.link_red-text:visited {
  color: #807e7e;
}

.c-menu_logo {
  margin-left: 2%;
}

.c-menu_logo.w--current {
  margin-left: 5%;
}

.footer_section {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: hsla(0, 9%, 96%, .93);
}

.footer_logo {
  width: 200px;
  margin-left: 2%;
  float: none;
}

.footer_wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer_text-block {
  position: static;
  display: block;
  width: auto;
  float: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right;
}

.footer_text-block.bottem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.footer_link {
  display: block;
  margin-top: 6px;
  margin-bottom: 6px;
  font-family: Montserrat, sans-serif;
  color: #e7382e;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
}

.footer_link:hover {
  color: #807e7e;
  font-size: 18px;
}

.footer_link.terms {
  margin-right: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-bottom: 0px;
  background-color: rgba(231, 56, 46, .21);
}

.divider.form {
  margin-top: 18px;
  margin-bottom: 18px;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: 80vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 500px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.about-section {
  margin-bottom: 100px;
  padding-top: 60px;
}

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.h2_heading {
  margin-bottom: 20px;
  font-family: Montserrat, sans-serif;
  color: #000;
  font-size: 30px;
  line-height: 30px;
  font-weight: 400;
  text-align: left;
}

.support-section {
  margin-top: 1px;
  margin-bottom: 30px;
}

.container_centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container_centered.integration {
  margin-top: 100px;
  padding-right: 79px;
  padding-left: 79px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.container_centered.product {
  position: static;
  z-index: 2;
  margin-top: 5%;
  margin-bottom: 0px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.faq_nav-col {
  position: relative;
  top: 0px;
  padding-right: 50px;
  padding-left: 50px;
}

.product_content {
  padding-bottom: 50px;
}

.table_container {
  position: fixed;
  left: 38px;
  top: 77px;
  width: 280px;
}

.list_text-items {
  font-family: Helvetica;
}

.footer_container {
  display: block;
  padding-top: 30px;
  padding-bottom: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #e7382e;
  background-color: #f5f3f3;
}

.footer_container.bottem {
  position: static;
  padding: 20px 21px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top-color: #dbdada;
  background-color: #f5f3f3;
}

.link-block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.footer_address {
  font-family: Montserrat, sans-serif;
  color: #e7382e;
  font-weight: 100;
  text-align: right;
}

.section-title-wrapper {
  display: block;
  width: 80%;
  margin-right: auto;
  margin-bottom: 60px;
  margin-left: auto;
  text-align: center;
}

.step-1 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 80px;
  padding-bottom: 80px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.feature-column {
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.feature-column.step {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.feature-block {
  display: block;
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.feature-block.wide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 90%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: justify;
}

.container-step {
  position: relative;
}

.container-step.centered {
  text-align: center;
}

.features-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.feature-icon {
  width: 40px;
  padding: 0px;
}

.feature-icon.overall {
  position: static;
  margin-bottom: 20px;
}

.button {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 15px 35px;
  float: left;
  clear: none;
  border-radius: 5px;
  background-color: #e7382e;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: background-color 400ms ease;
  transition: background-color 400ms ease;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
}

.button:hover {
  background-color: #807e7e;
}

.link {
  color: #e7382e;
}

.footer_bottom_bar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.into {
  padding-top: 60px;
  padding-bottom: 100px;
}

.feature-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 40px;
  margin-left: 40px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.spacer {
  min-height: 30px;
}

.text-block {
  width: 650px;
  margin-left: 60px;
  float: left;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.text-block.compare {
  width: 800px;
  margin-left: 100px;
}

.image-block {
  overflow: hidden;
}

.dashboard-hero-img {
  display: block;
  overflow: hidden;
  width: 900px;
  margin-left: 50px;
}

.p {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
}

.p.center {
  text-align: center;
}

.p.center.white {
  color: #fff;
}

.p.tick-boxes {
  margin-bottom: 0px;
}

.p.alt {
  text-align: center;
}

.feature-text-block {
  width: 600px;
  float: left;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.featured-img-block {
  display: block;
  width: auto;
  float: left;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.feature-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 40px;
  margin-left: 40px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.feature-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 40px;
  margin-left: 40px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.columns-2 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.column-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.integrate {
  padding-top: 100px;
  padding-bottom: 100px;
}

.why-churnly {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
  padding-bottom: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.div-block {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 250px;
  height: 200px;
  padding: 20px;
  float: left;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.testimonial-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 700px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonial-image {
  margin-right: 40px;
}

.testimonial {
  font-size: 24px;
}

.div-block-4 {
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #e7382e;
}

.customer-testimonial {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #fafafa;
}

.why {
  padding-top: 100px;
  padding-bottom: 100px;
}

.heading-2 {
  width: 50%;
  float: left;
}

.h1.center {
  text-align: center;
}

.h1.center.white {
  color: #fff;
}

.connect-section {
  padding-top: 50px;
}

.integrate-logo-blox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 200px;
  height: 200px;
  margin: 8px;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e7382e;
  border-radius: 11px;
}

.integrations-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.dashboard-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 78px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
  padding-bottom: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.lifetime-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.usp-text-block-2 {
  width: 500px;
  margin-right: 100px;
  float: left;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.image-15 {
  display: block;
  width: 600px;
}

.lifetime-section-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.usp-text-block {
  width: 500px;
  margin-left: 100px;
  float: left;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.logo-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.intergration-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.intgrations-text-block {
  width: 500px;
  float: left;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.accuracy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fafafa;
}

.icon-text {
  color: #000;
  font-weight: 700;
  text-align: center;
}

.intergration-image {
  width: 150px;
  margin-top: 17px;
  margin-bottom: 17px;
}

.image-16 {
  width: 700px;
}

.formsection {
  padding-top: 100px;
  padding-bottom: 100px;
}

.compare-section {
  padding-top: 40px;
  padding-bottom: 60px;
}

.control-section {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: transparent;
}

.grid-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tick-icon {
  width: 30px;
  height: 30px;
  min-height: 30px;
  min-width: 30px;
  margin-right: 15px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.columns-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.video-section {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #fff;
}

.video-section.tint {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: transparent;
}

.testimonial-slide {
  position: relative;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}

.testimonial-block-2 {
  position: relative;
  left: 0px;
  right: 0px;
  overflow: visible;
}

.container-2 {
  position: relative;
}

.container-2.testimonial-container {
  position: absolute;
  left: 0px;
  top: 50%;
  right: 0px;
  overflow: visible;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.testimonial-quote {
  font-size: 35px;
  line-height: 35px;
  font-weight: 300;
}

.testimonial-slider-arrow {
  width: 60px;
  height: 60px;
  margin-right: 30px;
  margin-left: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, .1);
  border-radius: 100%;
  background-color: transparent;
  -webkit-transition: border 200ms ease, background-color 200ms ease;
  transition: border 200ms ease, background-color 200ms ease;
  color: #000;
  font-size: 15px;
}

.testimonial-slider-arrow:hover {
  border-color: transparent;
  background-color: rgba(0, 0, 0, .1);
}

.testimonial-slider-2 {
  background-color: transparent;
}

.image-17 {
  float: right;
}

.image-18 {
  margin-bottom: 20px;
}

.testimonial-title {
  margin-bottom: 20px;
  text-align: center;
}

.video-lightbox-icon {
  height: 25px;
}

.video-lightbox-button {
  position: absolute;
  left: 0px;
  top: 53%;
  right: 0px;
  display: block;
  width: 60px;
  height: 60px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 16px;
  padding-left: 2px;
  border-radius: 100%;
  background-color: #5ec045;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  -webkit-transition: -webkit-transform 200ms ease;
  transition: -webkit-transform 200ms ease;
  transition: transform 200ms ease;
  transition: transform 200ms ease, -webkit-transform 200ms ease;
  text-align: center;
}

.video-lightbox-button:hover {
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, .5);
  -webkit-transform: translate(0px, -50%) scale(1.2);
  -ms-transform: translate(0px, -50%) scale(1.2);
  transform: translate(0px, -50%) scale(1.2);
}

.overall-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.overall-video-lightbox {
  display: block;
  overflow: hidden;
  width: 100%;
  height: 230px;
  border-radius: 4px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, .3);
}

.video-lightbox-image-block {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .1)), to(rgba(0, 0, 0, .1))), url('images/City-Graphic.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, .1)), url('images/City-Graphic.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.image-20 {
  margin-bottom: -48px;
}

.column-6 {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.column-7 {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.nav-link {
  margin-right: 4px;
  margin-left: 4px;
  padding: 14px 15px;
  font-family: Montserrat, sans-serif;
  color: #e7382e;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
}

.video-container {
  position: relative;
}

.image-22 {
  display: block;
  margin-left: 28px;
}

.div-block-5 {
  overflow: hidden;
}

.multi-form {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 10;
  display: none;
  height: auto;
  background-color: #fff;
}

.button-popup {
  margin-left: 10px;
  padding: 14px 30px;
  border-radius: 5px;
  background-color: #e7382e;
  -webkit-transition: background-color 400ms ease;
  transition: background-color 400ms ease;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}

.button-popup:hover {
  background-color: #807e7e;
}

.button-popup.w--current {
  color: #fff;
}

.customer-details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 80px;
  padding-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-step {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 500px;
  padding: 32px;
  float: left;
  clear: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #e7382e;
  border-radius: 5px;
  box-shadow: 1px 1px 15px -3px rgba(0, 0, 0, .28);
}

.form_text-field {
  margin: 10px;
  padding-top: 10px;
  float: left;
  border: 1px solid #e7382e;
  border-radius: 5px;
  font-family: Montserrat, sans-serif;
}

.form_text-field.step {
  margin-right: 0px;
  margin-left: 0px;
  float: none;
}

.checkbox-wrap {
  display: block;
  width: 45%;
  margin-right: 20px;
  margin-left: 20px;
  float: left;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.checkbox-field {
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
}

.step-3-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.form-label {
  color: #807e7e;
  font-size: 12px;
}

.form-label.title {
  font-family: Montserrat, sans-serif;
  color: #000;
  font-size: 20px;
  line-height: 24px;
  font-weight: 300;
}

.button-next {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 15px 35px;
  float: left;
  clear: none;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 5px;
  background-color: #e7382e;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  -webkit-transition: background-color 400ms ease;
  transition: background-color 400ms ease;
  font-family: Montserrat, sans-serif;
  color: #fff;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
}

.button-next:hover {
  background-color: #807e7e;
}

.form-container {
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-container.integration {
  margin-top: 100px;
  padding-right: 79px;
  padding-left: 79px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.form-container.product {
  position: static;
  z-index: 2;
  margin-top: 5%;
  margin-bottom: 0px;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

html.w-mod-js *[data-ix="fade-in-on-scroll"] {
  opacity: 0;
  -webkit-transform: translate(0px, 20px);
  -ms-transform: translate(0px, 20px);
  transform: translate(0px, 20px);
}

@media (max-width: 991px) {
  h1 {
    font-size: 55px;
    line-height: 60px;
    text-align: center;
  }
  h5 {
    color: #e7382e;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
  }
  .navbar {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .header_logo {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .c-menu_nav-link.menu {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;
  }
  .nav-menu {
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: 0%;
    display: block;
    width: 300px;
    margin-right: 0%;
    padding: 39px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f5f3f3;
  }
  .hero-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    padding-top: 25px;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    background-image: url('images/Home-background-hero.jpg');
    background-position: 100% 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .hero-section.product {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .paragraph {
    font-size: 18px;
    text-align: center;
  }
  .menu-button {
    padding: 24px;
    background-color: transparent;
  }
  .footer_logo {
    display: block;
    margin-right: auto;
    margin-left: auto;
    float: none;
  }
  .footer_wrapper {
    padding-left: 22px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .footer_text-block {
    margin-bottom: 24px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .footer_text-block.bottem {
    width: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer_link.terms {
    margin-right: 0px;
  }
  .divider {
    margin-top: 28px;
  }
  .container_centered.integration {
    margin-top: 40px;
    margin-bottom: 140px;
  }
  .container_centered.product {
    margin-top: -14px;
    margin-bottom: 60px;
    padding-right: 0px;
    padding-bottom: 31px;
    padding-left: 0px;
  }
  .faq_nav-col {
    padding-right: 19px;
    padding-left: 19px;
  }
  .product_content {
    padding-right: 2%;
    padding-left: 2%;
  }
  .table_container {
    left: 14px;
    top: 84px;
    width: 180px;
  }
  .unordered-list {
    padding-left: 15px;
  }
  .footer_container {
    display: block;
  }
  .section-title-wrapper {
    width: 85%;
    margin-bottom: 40px;
  }
  .step-1 {
    padding: 45px;
  }
  .feature-block {
    width: 90%;
  }
  .feature-block.wide {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left;
  }
  .features-row {
    margin-top: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .button {
    display: block;
  }
  .into {
    padding: 40px 60px 10px;
  }
  .text-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .text-block.compare {
    width: 90%;
    margin-left: 0px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .image-block {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
  .dashboard-hero-img {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 600px;
    margin-left: 0px;
  }
  .p {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  .feature-text-block {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .integrate {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .why-churnly {
    display: block;
    padding-bottom: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: space-around;
    -ms-flex-line-pack: distribute;
    align-content: space-around;
  }
  .div-block {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .div-block-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .why {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .heading-2 {
    width: auto;
  }
  .copyright-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .dashboard-section {
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .lifetime-section {
    padding-top: 40px;
    padding-bottom: 40px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .usp-text-block-2 {
    width: 50%;
    margin-right: 40px;
    margin-left: 20px;
  }
  .image-15 {
    width: 40%;
  }
  .lifetime-section-2 {
    padding-top: 40px;
    padding-bottom: 40px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .usp-text-block {
    width: 50%;
    margin-top: 21px;
    margin-left: 40px;
  }
  .logo-wrap {
    width: auto;
  }
  .intergration-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .intgrations-text-block {
    margin-bottom: 40px;
  }
  .intergration-image {
    width: 150px;
  }
  .image-16 {
    width: 50%;
  }
  .grid-wrap {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .tick-icon {
    margin-top: 10px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .video-section {
    padding: 45px;
  }
  .video-section.tint {
    padding-right: 10%;
    padding-left: 10%;
  }
  .testimonial-block-2 {
    padding-right: 0px;
  }
  .container-2.testimonial-container {
    padding-right: 30px;
    padding-left: 30px;
  }
  .testimonial-quote {
    width: auto;
    font-size: 20px;
  }
  .testimonial-slider-arrow {
    width: 30px;
    height: 30px;
    margin-right: -20px;
    margin-left: -20px;
    font-size: 13px;
  }
  .overall-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .overall-video-lightbox {
    width: auto;
    height: 330px;
  }
  .nav-link {
    display: block;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .nav-link.w--current {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .column-8 {
    padding-top: 20px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .button-popup {
    position: static;
    margin-right: 10px;
    text-align: center;
  }
  .form-step {
    margin-top: 20px;
  }
  .form_text-field {
    width: 100%;
  }
  .button-next {
    display: block;
  }
  .form-container {
    padding-top: 16px;
  }
  .form-container.integration {
    margin-top: 40px;
    margin-bottom: 140px;
  }
  .form-container.product {
    margin-top: -14px;
    margin-bottom: 60px;
    padding-right: 0px;
    padding-bottom: 31px;
    padding-left: 0px;
  }
}

@media (max-width: 767px) {
  h1 {
    width: auto;
    font-size: 50px;
    line-height: 50px;
    text-align: center;
  }
  h2 {
    text-align: left;
  }
  .c-menu_nav-link {
    margin-top: 39px;
    margin-bottom: 39px;
    color: #fff;
    text-align: center;
  }
  .c-menu_nav-link:hover {
    color: #807e7e;
  }
  .c-menu_nav-link.menu {
    color: #e7382e;
  }
  .nav-menu {
    display: block;
  }
  .hero-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .hero-section.product {
    padding-right: 20px;
    padding-left: 20px;
  }
  .h1_large-heading {
    font-size: 40px;
    line-height: 40px;
  }
  .footer_wrapper {
    padding-right: 20px;
    padding-left: 20px;
  }
  .footer_text-block {
    width: 50%;
  }
  .about-section {
    margin-top: 0px;
    margin-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .h2_heading {
    margin-top: 46px;
    margin-bottom: 20px;
    text-align: left;
  }
  .h2_heading.center {
    text-align: center;
  }
  .support-section {
    margin-top: 0px;
    margin-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .faq_nav-col {
    padding-right: 0px;
    padding-left: 0px;
  }
  .product_content {
    padding-bottom: 0px;
  }
  .table_container {
    position: static;
    width: auto;
  }
  .section-title-wrapper {
    width: 95%;
    margin-bottom: 40px;
  }
  .step-1 {
    padding: 25px;
  }
  .feature-column {
    padding-right: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
  }
  .feature-block {
    width: 100%;
  }
  .feature-block.wide {
    width: 100%;
  }
  .features-row {
    margin-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .feature-icon.overall {
    margin-bottom: 10px;
  }
  .into {
    padding-bottom: 40px;
  }
  .feature-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .text-block {
    width: auto;
    margin-right: 20px;
    margin-left: 20px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
  }
  .text-block.compare {
    margin-right: 0px;
  }
  .image-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }
  .dashboard-hero-img {
    width: 450px;
  }
  .p {
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    text-align: center;
  }
  .p.tick-boxes {
    text-align: left;
  }
  .feature-text-block {
    width: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .feature-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .feature-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .columns-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .column-2 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .column-3 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
  }
  .integrate {
    padding: 40px 20px 0px;
  }
  .testimonial-block {
    width: 400px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .testimonial-image {
    margin-bottom: 40px;
  }
  .customer-testimonial {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .why {
    padding: 60px 20px;
  }
  .connect-section {
    padding-right: 20px;
    padding-left: 20px;
  }
  .dashboard-section {
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .section {
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }
  .lifetime-section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .usp-text-block-2 {
    width: 90%;
    margin-right: 0px;
    margin-left: 0px;
  }
  .image-15 {
    width: 70%;
  }
  .lifetime-section-2 {
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .usp-text-block {
    width: 90%;
    margin-left: 0px;
  }
  .intgrations-text-block {
    width: 90%;
  }
  .accuracy {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .intergration-image {
    margin-top: 8px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .intergration-image.intercom {
    width: 100px;
  }
  .image-16 {
    width: 70%;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .formsection {
    padding-right: 20px;
    padding-left: 20px;
  }
  .compare-section {
    padding-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .control-section {
    padding: 60px 20px;
  }
  .columns-3 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .video-section {
    padding: 25px;
  }
  .testimonial-block-2 {
    position: relative;
    padding-right: 30px;
    padding-left: 30px;
    text-align: center;
  }
  .container-2.testimonial-container {
    position: relative;
    top: 0%;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .testimonial-quote {
    font-size: 14px;
    line-height: 22px;
  }
  .testimonial-slider-2 {
    height: auto;
  }
  .image-18 {
    position: relative;
    display: block;
    float: none;
    clear: both;
  }
  .video-column-right-2 {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }
  .button-popup {
    border: 1px solid #fff;
  }
  .form-step {
    width: 100%;
  }
  .checkbox-wrap {
    width: auto;
  }
  .step-3-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 479px) {
  h1 {
    font-size: 40px;
    line-height: 40px;
    text-align: left;
  }
  h2 {
    font-size: 24px;
    line-height: 28px;
    text-align: left;
  }
  h3 {
    text-align: left;
  }
  .navbar {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .header_logo {
    display: inline-block;
    width: 150px;
    padding-top: 6px;
  }
  .c-menu_nav-link {
    margin-top: 29px;
    margin-bottom: 29px;
    color: #fff;
    text-align: center;
  }
  .nav-menu {
    display: block;
    width: 250px;
    padding: 18px;
  }
  .hero-section {
    height: auto;
    background-image: url('images/Path-2472x.png');
    background-position: 100% 100%;
    background-size: cover;
  }
  .hero-section.integrations {
    padding-right: 20px;
    padding-left: 20px;
  }
  .hero-section.product {
    background-image: url('images/Path--1.png');
  }
  .h1_large-heading {
    margin-top: 40px;
    font-size: 40px;
    line-height: 40px;
  }
  .menu-button {
    padding-right: 23px;
  }
  .footer_wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .footer_text-block {
    width: auto;
    margin-right: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
  .footer_link {
    text-align: center;
  }
  .about-section {
    margin-top: 0px;
    margin-bottom: 50px;
    padding-top: 20px;
  }
  .h2_heading {
    font-size: 24px;
    line-height: 28px;
    text-align: left;
  }
  .h2_heading.center {
    text-align: center;
  }
  .support-section {
    margin-top: 6px;
    margin-bottom: 50px;
  }
  .container_centered.integration {
    padding-right: 0px;
    padding-left: 0px;
  }
  .faq_nav-col {
    padding-right: 0px;
    padding-left: 0px;
  }
  .product_content {
    padding-right: 2%;
    padding-left: 2%;
  }
  .link-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .footer_address {
    text-align: center;
  }
  .section-title-wrapper {
    width: 100%;
    margin-bottom: 30px;
  }
  .step-1 {
    padding: 25px;
  }
  .feature-block.wide {
    font-size: 13px;
  }
  .feature-icon {
    width: 30px;
  }
  .button {
    display: block;
    padding-right: 34px;
    padding-left: 34px;
    font-size: 16px;
    text-align: center;
  }
  .into {
    padding-right: 20px;
    padding-left: 20px;
  }
  .feature-1 {
    margin-right: 20px;
    margin-left: 20px;
  }
  .text-block {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .text-block.compare {
    width: auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
  }
  .dashboard-hero-img {
    width: auto;
    margin-left: 14px;
  }
  .p {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    text-align: left;
  }
  .p.alt {
    text-align: left;
  }
  .feature-2 {
    margin-right: 20px;
    margin-left: 20px;
  }
  .feature-3 {
    margin-right: 20px;
    margin-left: 20px;
  }
  .columns-2 {
    padding-bottom: 0px;
  }
  .integrate {
    padding-right: 20px;
    padding-left: 20px;
  }
  .div-block-2 {
    padding-top: 0px;
  }
  .testimonial-block {
    width: auto;
  }
  .testimonial-image {
    margin-right: 0px;
  }
  .customer-testimonial {
    padding-right: 20px;
    padding-left: 20px;
  }
  .why {
    padding-top: 40px;
  }
  .connect-section {
    padding-top: 20px;
  }
  .integrate-logo-blox {
    width: 120px;
    height: 120px;
  }
  .integrations-container {
    padding-top: 20px;
  }
  .image-15 {
    width: 90%;
  }
  .lifetime-section-2 {
    padding-left: 0px;
  }
  .usp-text-block {
    margin-top: 0px;
  }
  .intgrations-text-block {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
  .intergration-image {
    width: 100px;
  }
  .intergration-image.intercom {
    width: 60px;
  }
  .image-16 {
    width: 90%;
  }
  .accuracy-image {
    width: 80%;
  }
  .formsection {
    padding-right: 20px;
    padding-left: 20px;
  }
  .compare-section {
    padding-top: 0px;
  }
  .tick-icon {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
  }
  .columns-3 {
    padding-top: 20px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .video-section {
    padding: 25px;
  }
  .video-section.tint {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .testimonial-block-2 {
    position: relative;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    padding-right: 0px;
    padding-left: 0px;
  }
  .container-2.testimonial-container {
    padding-right: 0px;
    padding-left: 0px;
  }
  .testimonial-quote {
    padding-right: 10%;
    padding-left: 10%;
    font-size: 18px;
    line-height: 20px;
  }
  .testimonial-slider-arrow {
    position: absolute;
    margin-right: -10px;
    margin-left: -10px;
  }
  .video-lightbox-icon {
    height: 20px;
  }
  .video-lightbox-button {
    width: 50px;
    height: 50px;
    padding-top: 13px;
  }
  .overall-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .overall-video-lightbox {
    height: 140px;
  }
  .column-8 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .button-popup {
    padding-top: 14px;
    padding-bottom: 14px;
    border: 1px solid #fafafa;
    text-align: center;
  }
  .customer-details {
    padding-right: 20px;
    padding-left: 20px;
  }
  .form-step {
    padding-right: 15px;
    padding-left: 15px;
  }
  .checkbox-wrap {
    width: auto;
    margin-top: 30px;
    margin-right: 10px;
    margin-left: 10px;
  }
  .step-3-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .button-next {
    display: block;
    padding-right: 34px;
    padding-left: 34px;
    font-size: 16px;
    text-align: center;
  }
  .form-container.integration {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@font-face {
  font-family: 'Bebasneue';
  src: url('./fonts/bebasneue_light-webfont.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Bebasneue';
  src: url('./fonts/bebasneue_regular-webfont.woff2') format('woff2'), url('./fonts/BebasNeue-Regular.eot') format('embedded-opentype'), url('./fonts/BebasNeue-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}